import styled from 'styled-components';

import DefLink from '@/components/Link';
import { metrics, colors } from '@/theme';

const { header, footer, mobile } = metrics;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 50%;
  height: ${header.desktop}px;
  background: ${colors.white};
  padding: 0 20px;
  z-index: 10;
  @media (max-width: ${mobile}px) {
    height: ${header.mobile}px;
    right: 0;
  }
`;

export const Main = styled.main`
  min-height: calc(100% - ${footer.desktop}px);
  display: flex;
  flex-direction: column;
  padding-top: ${header.desktop}px;
  @media (max-width: ${mobile}px) {
    min-height: calc(100% - ${footer.mobile}px);
    padding-top: ${header.mobile}px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  height: ${footer.desktop}px;
  width: 50%;
  background: ${colors.white};
  padding: 0 20px;
  @media (max-width: ${mobile}px) {
    width: 100%;
    height: ${footer.mobile}px;
  }
`;

export const LogoLink = styled(DefLink)`
  display: block;
`;

export const Link = styled(DefLink)`
  margin-right: 60px;
  &:last-child {
    margin-right: 0;
  }
`;
