import colors from './colors';

const { black, white, gray } = colors;

const defStyles = `
  font-family: Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${black};
`;

export default {
  title: `
    ${defStyles}
    font-size: 49px;
    line-height: 1.16;
  `,
  subTitle: `
    ${defStyles}
    font-size: 35px;
    line-height: 1.43;
  `,
  text: `
    ${defStyles}
    font-size: 23px;
    line-height: 1.3;
  `,
  smallText: `
    ${defStyles}
    font-size: 19px;
    line-height: 1.37;
  `,
  button: `
    ${defStyles}
    font-size: 23px;
    line-height: 1.17;
    text-align: center;
    &:hover {
      color: ${white};
    }
    &:disabled {
      color: ${gray};
    }
  `,
  link: `
    ${defStyles}
    font-size: 19px;
    line-height: 1.37;
    text-decoration: underline;
    &:hover {
      color: ${gray};
    }
    &:active {
      color: ${black};
    }
  `,
};
