const React = require('react');

const { GlobalStyle } = require('./src/theme');
const Layout = require('./src/components/Layout').default;

exports.wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    <Layout>{element}</Layout>
  </>
);
