import { createGlobalStyle } from 'styled-components';

import neueHaasUnicaProRegularWoff from '@/assets/fonts/NeueHaasUnicaPro-Regular.woff';
import neueHaasUnicaProRegularWoff2 from '@/assets/fonts/NeueHaasUnicaPro-Regular.woff2';

import metrics from './metrics';
import colors from './colors';
import fonts from './fonts';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Regular";
    font-stretch: normal;
    font-style: normal;
    font-display: swap;
    src: url(${neueHaasUnicaProRegularWoff}) format("woff"), url(${neueHaasUnicaProRegularWoff2}) format("woff2");
  }
  html {
    margin: 0;
    padding: 0;
  }
  body {
    min-width: 350px;
    margin: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
  }
  html, body, #___gatsby, #gatsby-focus-wrapper {
    height: 1px;
    min-height: 100%;
  }
  input, textarea {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    outline: none;
    border-radius: 0;
    padding: 0;
  }
  button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  * {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
  }
`;

export { metrics, colors, fonts };
