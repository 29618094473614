import React from 'react';
import PropTypes from 'prop-types';

import Logo from '@/assets/svg/Logo';

import { Header, Main, Footer, LogoLink, Link } from './styles';

const Layout = ({ children }) => (
  <>
    <Header>
      <LogoLink to="/">
        <Logo />
      </LogoLink>
    </Header>
    <Main>{children}</Main>
    <Footer>
      <Link to="/imprint">Impressum</Link>
      <Link to="/privacy">Datenschutz</Link>
    </Footer>
  </>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
};

export default Layout;
