export default {
  mobile: 1023,
  header: {
    desktop: 55,
    mobile: 55,
  },
  footer: {
    desktop: 55,
    mobile: 80,
  },
};
