import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="157" height="54" viewBox="0 0 157 54">
    <text fill="none" fillRule="evenodd" fontFamily="Regular" fontSize="35">
      <tspan x="0" y="41" fill="gray">
        medi
      </tspan>
      <tspan x="78.505" y="41" fill="#000">
        trova
      </tspan>
    </text>
  </svg>
);
